$defaultGradientTime: 1s;
$nameGradientTime: 2s;
$positionGradientTime: 4s;
$animationDelay: 1s;

.App {
  text-align: center;
  min-height: 100vh;
  font-family: 'Raleway', sans-serif;
  background-color: #c6fafa;
}

.Main-Page {
  height: 100%;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.Presentation {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  > span {
    font-size: 3vh;
    animation: fadeIn linear $defaultGradientTime * 2;
    -webkit-animation: fadeIn linear $defaultGradientTime * 2;
    -moz-animation: fadeIn linear $defaultGradientTime * 2;
    -o-animation: fadeIn linear $defaultGradientTime * 2;
    -ms-animation: fadeIn linear $defaultGradientTime * 2;
  }

  > span.Name {
    opacity: 0;
    font-size: 10vh;
    animation: fadeIn linear $nameGradientTime forwards;
    animation-delay: $animationDelay;
    -webkit-animation: fadeIn linear $nameGradientTime forwards;
    -webkit-animation-delay: $animationDelay;
    -moz-animation: fadeIn linear $nameGradientTime forwards;
    -moz-animation-delay: $animationDelay;
    -o-animation: fadeIn linear $nameGradientTime forwards;
    -o-animation-delay: $animationDelay;
    -ms-animation: fadeIn linear $nameGradientTime forwards;
    -ms-animation-delay: $animationDelay;
  }

  > span.Position {
    opacity: 0;
    font-family: 'Courier New', monospace;
    font-size: 5vh;
    animation: fadeIn linear $positionGradientTime forwards;
    animation-delay: $animationDelay * 2;
    -webkit-animation: fadeIn linear $positionGradientTime forwards;
    -webkit-animation-delay: $animationDelay * 2;
    -moz-animation: fadeIn linear $positionGradientTime forwards;
    -moz-animation-delay: $animationDelay * 2;
    -o-animation: fadeIn linear $positionGradientTime forwards;
    -o-animation-delay: $animationDelay * 2;
    -ms-animation: fadeIn linear $positionGradientTime forwards;
    -ms-animation-delay: $animationDelay * 2;
  }
}

.Profile-Image {
  height: 30vh;
  width: 30vh;
  border-radius: 50%;
  opacity: 0;
  animation: fadeIn linear 1s forwards;
  animation-delay: 3.2s;
}

.Card {
  background-color: white;
  width: 70vw;
  height: 60vh;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Card-Shadow {
  @extend .Card;
  background-color: #92c9c9;
  animation: moveShadow linear 1s forwards;
}

.Card-Shadow-Second {
  @extend .Card;
  background-color: #6aacac;
  animation: moveSecondaryShadow linear 1.2s forwards;
}

@keyframes fadeIn {
  from {opacity:0;}
  to {opacity:1;}
}

@-moz-keyframes fadeIn {
  from {opacity:0;}
  to {opacity:1;}
}

@-webkit-keyframes fadeIn {
  from {opacity:0;}
  to {opacity:1;}
}

@-o-keyframes fadeIn {
  from {opacity:0;}
  to {opacity:1;}
}

@-ms-keyframes fadeIn {
  from {opacity:0;}
  to {opacity:1;}
}

@keyframes moveSecondaryShadow {
  from {
    -webkit-transform: translate(-100%, 0%);
    transform: translate(-100%, 0%);
  }
  to {
    -webkit-transform: translate(-46%, -55%);
    transform: translate(-46%, -55%);
  }
}

@keyframes moveShadow {
  from {
    -webkit-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  to {
    -webkit-transform: translate(-60%, -40%);
  transform: translate(-60%, -40%);
  }
}
